import { IPageInfo } from './common';

// 题目类型
export enum QuestionType {
  SingleChoice = 1, // 单选
  MultipleChoice, // 多选
  QuestionAndAnswer, // 问答
  Judgment, // 判断
  UnCertainMultiple, // 不定项选择
  FillInBlanks, // 填空
}

export interface IQueryQuestionLibrary extends IPageInfo {
  questionLibraryName?: string;
}

export interface IQueryQuestion extends IPageInfo {
  questionLibraryId?: string;
  questionType?: QuestionType;
}

export interface IQuestionLibraryItem {
  questionLibraryId: string;
  questionLibraryName: string;
  owners?: { userId: number; userName: string }[];
  questionCount?: number;
  createBy?: string;
  updateBy?: string;
  createTime?: string;
  updateTime?: string;
}

export interface IQuestionBase {
  questionType: QuestionType;
  body: string;
  options?: string[];
  answer?: number[];
  solution?: string;
  textAnswer?: string[];
}

export interface IQuestionItem extends IQuestionBase {
  questionId: string;
  questionLibraryId?: string;
}

export interface IQueryPaper extends IPageInfo {
  paperName?: string;
}

export enum PaperTypeEnum {
  exam = '1',
  questionnaire = '2',
}

export interface IPaperVersionItem {
  createBy?: string;
  createTime?: Date;
  paperScore?: number;
  paperVersionId?: string;
  published?: boolean;
  randomOption?: boolean;
  randomQuestion?: boolean;
  duration?: number;
  updateBy?: string;
  updateTime?: Date;
}

export interface IPaperVersionInfo extends IPaperVersionItem {
  paper?: Omit<IPaperItem, 'version'>;
  questions?: {
    paperVersionId?: string;
    questionId?: string;
    questionScore?: number;
    question: IQuestionItem;
  }[];
}

export interface IPaperItem extends IPageInfo {
  paperId?: string;
  paperName: string;
  paperType: PaperTypeEnum;
  paperDescription?: string;
  createBy?: string;
  updateBy?: string;
  createTime?: string;
  updateTime?: string;
  versions?: IPaperVersionItem;
}

export interface ICreatePaper {
  paperName: string;
  paperType: string;
  paperDescription?: string;
}

export interface IUpdatePaper extends ICreatePaper {
  paperId: string;
}

export interface ISubmitPaper {
  paperVersionId: string;
  randomOption: boolean;
  randomQuestion: boolean;
  duration: number;
  scoreInfo: {
    questionId: string;
    questionScore: number;
  }[];
}

export interface IGetPaperVersionReq {
  paperId: string;
  offset?: number;
  limit?: number;
}

export interface IPaperVersionItem {
  paperVersionId?: string;
  randomQuestion?: boolean;
  randomOption?: boolean;
  duration?: number;
  paperScore?: number;
  published?: boolean;
}

export interface IQueryExamination extends IPageInfo {
  examName?: string;
  count?: number;
  gradePublishTime?: string;
  startTime?: string;
  endTime?: string;
}

export interface IExamInfo {
  examId?: string;
  examName?: string;
  count?: number;
  gradePublish?: number;
  gradePublishTime?: string;
  videoMonitor?: number;
  videoMonitorCount?: number;
  screenMonitor?: number;
  screenMonitorCount?: number;
  passGrade?: number;
  startTime?: string;
  endTime?: string;
}
